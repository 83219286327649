import Swal from "sweetalert2";
import General from "@/services/General";
import Auth from "@/services/Auth";


export default {
    data() {
        return {
            access_objects_env:process.env.VUE_APP_ACCESS_OBJECTS,
            access_objects: localStorage.getItem("access_objects"),
            itemsPerPageOpts: [10, 25, 50, 100],
            exportingData:false,
            navigationMenu: [],
            testMenu:[]
        }
    },
    computed: {
        visibleFields() {
            return this.fields.filter(field => field.visible);
        },
        userRole() {
            return this.$store.state.userRole;
        },
        isSuperAdmin(){
            if (localStorage.getItem('userRole') !== null) {
                var userRole = localStorage.getItem('userRole');
                return userRole == 'super_admin' ? true : false;
            }else{
                return false;
            }
        }
    },
    methods: {
        async getNavigationMenu(user_id){
            let access_objects_env = JSON.parse(this.access_objects_env)
            localStorage.setItem("access_objects", access_objects_env);

            /*await Auth.getNavigationMenu(user_id).then((response) => {
                this.navigationMenu = response.data.data
                localStorage.setItem("access_objects", this.navigationMenu);
            })*/
        },
        roleAccessObjects(){
            return this.access_objects.split(',');
        },
        checkObjectAccess(object){
            return this.roleAccessObjects().includes(object);
        },
        async checkObjectAccessCall(object) {
            try {
                await Auth.hasObjectPermission({ object: object });
                return true;
            } catch (error) {
                return false;
            }
        },
        toggleBusy() {
            this.isBusy = !this.isBusy
        },
        resetFilters(){

            for (const prop of Object.getOwnPropertyNames(this.filterInput)) {
                if(Array.isArray(this.filterInput[prop])){
                    this.filterInput[prop] = [];
                }else{
                    if(prop == 'country' ){
                        this.filterInput[prop] = null;
                    }else{

                        let refName = prop+'Autocomplete';
                        if(this.$refs[refName]){
                            this.$refs[refName].clearInput();
                        }
                            this.filterInput[prop] = "";
                    }
                }

            }
            this.tableData = [];
        },
        format_status(status, fontSize='font-size-14'){
            if(status){
                var status_temp = status.replace('_', ' ').toLowerCase();
                var status_formated ='';
                switch(status.toLowerCase()) {
                    case 'processing':
                        status_formated = "<span class='badge bg-warning "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'processed':
                        status_formated = "<span class='badge bg-success "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'completed':
                        status_formated = "<span class='badge bg-success "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'processed manually':
                        status_formated = "<span class='badge bg-info "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'undefined':
                        status_formated = "<span class='badge bg-danger "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'refunded':
                        status_formated = "<span class='badge bg-info "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'cancelled':
                        status_formated = "<span class='badge bg-danger "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'active':
                        status_formated = "<span class='badge bg-success "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'Active':
                        status_formated = "<span class='badge bg-success "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'verified':
                        status_formated = "<span class='badge bg-success "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'merged':
                        status_formated = "<span class='badge bg-success "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'related':
                        status_formated = "<span class='badge bg-warning "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'created':
                        status_formated = "<span class='badge bg-warning "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'deleted':
                        status_formated = "<span class='badge bg-danger "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'unverified':
                        status_formated = "<span class='badge bg-danger "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'passive':
                        status_formated = "<span class='badge bg-danger "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'valid':
                        status_formated = "<span class='badge bg-success "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'new':
                        status_formated = "<span class='badge bg-success "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'cancel':
                        status_formated = "<span class='badge bg-danger "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'yes':
                        status_formated = "<span class='badge bg-success "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'no':
                        status_formated = "<span class='badge bg-warning "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'confirmed':
                        status_formated = "<span class='badge bg-success "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    case 'issued':
                        status_formated = "<span class='badge bg-success "+fontSize+"'>"+status_temp+"</span>";
                        break;
                    default:
                        status_formated = "<span class='badge bg-warning "+fontSize+"'>"+status_temp+"</span>";
                }
                return status_formated;
            }
        },
        format_order_type(type, fontSize='font-size-14'){
            if(type){
                var type_formated = "<span class='badge bg-success "+fontSize+"'>"+type+"</span>";
            }
            return type_formated;

        },
        successmsg(title='Success', html='', position='center') {
            Swal.fire({
                position: position,
                icon: "success",
                title: title,
                html:html,
                showConfirmButton: false,
                timer: 2000
            });
        },

        failedmsg(title='Failed', html='', position='center') {
            Swal.fire({
                position: position,
                icon: "error",
                title: title,
                html:html,
                showConfirmButton: false,
                timer: 50000
            });
        },

        warningmsg(title='Warning', html='', position='center') {
            Swal.fire({
                position: position,
                icon: "warning",
                title: title,
                html:html,
                showConfirmButton: true,
                timer: 50000
            });
        },

        onPageChange(newPage){
            this.currentPage = newPage;
            this.handlePaginationChange()
        },
        onItemsPerPageChange(ItemsPerPage) {
            this.perPage = ItemsPerPage;
            this.currentPage = 1; // Reset page to 1 when changing items per page
            this.handlePaginationChange()
        },
        updateSortDesc(sortDesc){
            this.sortDesc = sortDesc;
            this.handlePaginationChange()
        },
        updateSortBy(sortBy){
            this.sortBy = sortBy;
            this.handlePaginationChange()
        },
        setPaginationFilters(data){
            let sortDesc = (this.sortDesc === true) ? 'DESC' : 'ASC';
            let sortBy = (this.sortBy !== undefined) ? this.sortBy : '';

            data += '&page='+this.currentPage+'&limit='+this.perPage+'&orderBy='+sortBy+'&order='+sortDesc

            if(this.filter.trim() !== ''){
                data += '&filter='+this.filter
            }

            return data;
        },
        setExportFilters(data){
            let sortDesc = (this.sortDesc === true) ? 'DESC' : 'ASC';
            let sortBy = (this.sortBy !== undefined) ? this.sortBy : '';

            data += '&limit=-1&orderBy='+sortBy+'&order='+sortDesc

            if(this.filter.trim() !== ''){
                data += '&filter='+this.filter
            }

            return data;
        },
        clearSearch(){
            this.filter = '';
            this.handlePaginationChange()
        },

        async dashboardDefinitions(id, key){
            var filter = '&id='+id+'&key='+key;
            await General.getDashboardDefinitions(filter).then((response) => {
                var result = response.data.data[0];
                if(key == 'columns') {
                    this.fields = JSON.parse(result.value);
                } else if(key == 'cashless'){

                    if(result.value == 'true'){
                        this.cashless = true;
                    } else {
                        this.cashless = false;
                    }
                }

                else {
                    return result.value;
                }

            })
        },

        copyToClipboard(content) {
            const textarea = document.createElement('textarea');
            textarea.value = JSON.stringify(content);
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
        },

        sortCountries(a, b, sortBy) {
            // Define the countries you want to move to the top
            const topCountries = ["Netherlands", "Germany", "Belgium", "Turkey"];

            // Get the values for sorting based on the sortBy parameter
            const valueA = sortBy === 'country_name' ? a.country_name : a.name;
            const valueB = sortBy === 'country_name' ? b.country_name : b.name;

            // Get the indices of the countries in the 'topCountries' array
            const indexA = topCountries.indexOf(valueA);
            const indexB = topCountries.indexOf(valueB);

            // If both countries are in the 'topCountries' array, sort them based on their order in 'topCountries'
            if (indexA > -1 && indexB > -1) {
                return indexA - indexB;
            }

            // If only country 'a' is in 'topCountries', move it up
            if (indexA > -1) {
                return -1;
            }

            // If only country 'b' is in 'topCountries', move it up
            if (indexB > -1) {
                return 1;
            }

            // If neither country is in 'topCountries', maintain their original order
            return 0;
        },

        preferredCountryOrder(countries, sortBy = 'country_name') {
            return countries.sort((a, b) => this.sortCountries(a, b, sortBy));
        },

        toRoute(route, data){
            let routeTo = '';

            if (data != undefined) {
                routeTo = route + data;
            }else{
                routeTo = route;
            }

            this.$router.push(`${routeTo}`)
        }
    }
}