import axios from 'axios'
import Auth from '@/services/Auth'

const Http = axios.create({
    baseURL: process.env.VUE_APP_API_BASEURL
})

Http.interceptors.request.use((config) => {
    const access_token = localStorage.getItem('access_token')
    const csrf_token = localStorage.getItem('csrf_token')

    config.headers = {
        ...config.headers, 'SCAPI-KEY': `${process.env.VUE_APP_API_KEY}`,
    };
    config.headers = {
        ...config.headers, 'CSRF-TOKEN': `${csrf_token}`,
    };
    if(access_token){
        config.headers = {
            ...config.headers, Authorization: `Bearer ${access_token}`,
        };
    }
    return config
});

Http.interceptors.response.use(
    (res) => {
        //console.log(res);
        return res;
    },
    async (err) => {

        const error_code =  (typeof err.response.data.error_code != 'undefined') ? err.response.data.error_code : '';
        const originalConfig = err.config;
        if (
            originalConfig.url !== "/auth/login"
            &&  originalConfig.url !== "/auth/validate"
            && err.response
        ) {
            if (err.response.status === 401) {
                //check token
                Auth.validate()
                    .then((response) => {

                    })
                    .catch((error) => {
                        localStorage.clear()
                        window.location.href = '/login'
                    })

            }else if(error_code == '2FA_REQUIRED') {
                window.location.href = '/profile'
            }
        }

        return Promise.reject(err);

    }

)

export default Http