import Auth from '@/services/Auth'

export const state = {
    user_id: localStorage.getItem('user_id'),
    username: localStorage.getItem('username'),
    access_token: localStorage.getItem('access_token'),
    expire: localStorage.getItem('expire'),
}

export const mutations = {
    setUser(state, user_id) {
        state.user_id = user_id;
    },
    setUsername(state, username) {
        state.username = username;
    },
    setAccessToken(state, token) {
        state.access_token = token;
    },
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.access_token
    },
}


export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs out the current user.
    logout() {
        localStorage.clear();
        window.location.href = '/login'
    },

    validate({ commit, state }) {
        const validToken = '';
        Auth.validate()
            .catch(function (error) {
                this.validToken = false
            });

        return validToken;
    },

}